import { BaseConfig, ConfigModel, IconConfig } from "../src/config/ConfigModel";

// The follwoing URL should be the Prod-URL, so the Pipeline doesn't break the Prod deployment.
const devBaseUri = "https://sw.campana-schott.com";
//const devBaseUri = "https://smartworkspace-test.azurewebsites.net";

const externalSettings = window.externalConfig;
const iconConf: IconConfig = {
	icons: {
        meetingroomIcon: require("../src/styles/CSStyle/MeetingroomIcon.png"),
        flexDeskIcon: require("../src/styles/CSStyle/FlexDeskIcon.png"),
        projectSpaceIcon: require("../src/styles/CSStyle/ProjectSpaceIcon.png"),
        weatherIcon: require("../src/styles/CSStyle/WeatherIcon.png"),
        meetingroomIconOccupied: require("../src/styles/CSStyle/MeetingroomIconOccupied.png"),
        flexDeskIconOccupied: require("../src/styles/CSStyle/FlexDeskIconOccupied.png"),
        projectSpaceIconOccupied: require("../src/styles/CSStyle/ProjectSpaceIconOccupied.png")
    }
}

const localConfig: BaseConfig = {
    apiConfig: {
        baseURL: devBaseUri
    },
    authConfig: {
        tenant: "9e73e135-fe7a-447f-baba-b0312d3aa55d",
        clientId: "81640bf1-ed1b-4d86-b9d2-2086b244f6e7",
        endpoints: {
            api: "81640bf1-ed1b-4d86-b9d2-2086b244f6e7"
        }
    },
    adHocConfig: {
        serviceAccountName: "smartoffice_exchange_access ",
        adHocMeetingTitle: "Ad Hoc"
    },
    palette: {
        themePrimary: '#008caa',
        themeLighterAlt: '#f2fafc',
        themeLighter: '#cbeaf2',
        themeLight: '#a1d9e6',
        themeTertiary: '#52b6cd',
        themeSecondary: '#1698b5',
        themeDarkAlt: '#007e9a',
        themeDark: '#006a82',
        themeDarker: '#004e60',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#c2c2c2',
        neutralSecondary: '#858585',
        neutralPrimaryAlt: '#4b4b4b',
        neutralPrimary: '#333333',
        neutralDark: '#272727',
        black: '#1d1d1d',
        white: '#ffffff',
    },
    floorMapRendering: {
        view: "3D",
        areaStyleFillOpacity: "0.5",
        deskOccupiedColor: "#a0002d",
        deskLockedColor: "#000000",
        deskMaybeOccupiedColor: "#FFFF00",
        deskFreeColor: "#78af05",
        meetingRoomOccupiedColor: "#a0002d",
        meetingRoomFreeColor: "#78af05"
    }
}

let _config: ConfigModel | undefined = undefined;
if (externalSettings) {
	console.log("Using external settings");
	_config = {
		...iconConf,
		...externalSettings
	};
	_config.apiConfig.baseURL = window.location.origin
}
else {
	console.log("Using internal settings");
	_config = {
		...iconConf,
		...localConfig
	}
}

console.log("Configured Settings:", _config);
export const CONFIG = _config;
